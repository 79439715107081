<template>
  <div data-v-68d7bcd4="" class="indexs">
    <div data-v-68d7bcd4="" class="web_show">
      <div data-v-68d7bcd4="" class="logo_box"><img data-v-68d7bcd4="" src="../assets/logo.png" alt=""></div><span
        data-v-68d7bcd4="">Open with an app</span>
      <div data-v-68d7bcd4="" class="download_box"><a data-v-68d7bcd4="" target="_self" href="/toshibavip.apk"
          download="toshibavip.apk" style="color: rgb(78, 78, 78); font-size: 16px;"><img data-v-68d7bcd4=""
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABYklEQVRoQ+3a3RGCMAwA4HQJh9AldAmf6SSek5Rnl9AldAiXqJcT7zi1JW0S4CC8eRd+voQWGnSwkM0txAEG6VfSe3/lVDaEcODsj/uKVKSD7Csv5maQXuasIn/GiN1aNkZsjCTmV5t+34mx50j/BrHniD1HficMGyOkMeK938YYN5Q3WufcCQCqX1FijGfieZ4hhMe/2OxgRwwAXABgRzmRYswdAI4pBGk9MgPMIIIEwaAJMSQEGTIRhowogoyMKUIUQ0bCFCOqIMqYKkQ1RAlTjWBBhDEsBBsihGEjRCBMjAhCDFKJEUOIQgoxoghxCBEjjlCBDGBUEGqQBEYNoQr5wuDP7HoCAzibSBcldwHdEgByiyIO4LOvOkTiIinHMAglS2PGrKMiTdNgm2c2W9u2ybbRUDsIPzvX9qukE5DtSBpEOt2E41lFsDHH+msGIctFIbmvv+uYfovSNXHwYiryAhi53DPcFIGeAAAAAElFTkSuQmCC"
            alt=""></a></div>
    </div>
    <div data-v-68d7bcd4="" class="index_title">
      <p data-v-68d7bcd4="" class="top_title">Welcome Back</p>
      <p data-v-68d7bcd4="" class="bot_title">Quality Guarantee</p>
    </div>
    <div data-v-68d7bcd4="" class="swiper_box">
      <div data-v-68d7bcd4="" style="height:515px;!important" class="my-swipe van-swipe">
        <div class="van-swipe__track">
          <div class="slideshow-container">
            <div class="slides">
              <img src="../assets/1.jpg" alt="one" class="pic" name="1">
              <img src="../assets/2.jpg" alt="two" class="pic" name="2">
              <img src="../assets/3.jpg" alt="three" class="pic" name="3">
              <img src="../assets/4.jpg" alt="four" class="pic" name="4">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-v-68d7bcd4="" class="index_list">
      <div data-v-68d7bcd4="" class="list_content">
        <ul data-v-68d7bcd4="" class="list_ul">
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/51iEBQzCL5L._UL1500_.jpg&quot;);">
                </div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Joyalukkas 18k (750) Rose Gold and Solitaire
                Pendant for Girls</div>
              <p data-v-68d7bcd4="" class="price">₹ 38576.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/71JvL64Y3cL._UY695_.jpg&quot;);">
                </div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Ratnavali Jewels American Diamond Traditional
                Fashion Jewellery Green Necklace Pendant Set with Earring for Women/Girls RV2916G</div>
              <p data-v-68d7bcd4="" class="price">₹ 2899.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/71YWzTc2omL._UY695_.jpg&quot;);">
                </div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Swasti Jewels Heart Shape Fashion Jewellery
                Set Pendant Earrings for Women</div>
              <p data-v-68d7bcd4="" class="price">₹ 4559.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/4.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Om Jewells Rhodium Plated Blue Crystal
                Jewellery Combo of Designer Drop Pendant Set with Adjustable Bangle Kada and Adjustable Solitaire Finger
                Ring for Girls and Women CO1000209</div>
              <p data-v-68d7bcd4="" class="price">₹ 1599.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/5.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Sukkhi Gleaming Pearl Gold Plated Wedding
                Jewellery Kundan Peacock Meenakari Multi-String Necklace Set for Women (2191NGLDPP1560)</div>
              <p data-v-68d7bcd4="" class="price">₹ 1745.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/6.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Ananth Jewels 925 Sterling Silver BIS
                Hallmarked Heart Bracelet for Women</div>
              <p data-v-68d7bcd4="" class="price">₹ 9000.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/7.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Handicraft Kottage ® 1gm 22Ct Gold Plated
                Pendant and Chain for Men/Women/Girls</div>
              <p data-v-68d7bcd4="" class="price">₹ 999.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/8.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Mansiyaorange Combo of Two Party One Gram Gold
                Forming Long Haram and Choker Multi Color Jewellery Necklace/Juelry/jwelry Set Jewellery for Women</div>
              <p data-v-68d7bcd4="" class="price">₹ 3199.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/9.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Young &amp; Forever Fashion Jewellery Elite
                Rose Gold Plated Geometric Shape Stud Earring Pendant Set for Women Princess Length Delicate Chain Cubic
                Zirconia Necklace Set for Girls Jewelry</div>
              <p data-v-68d7bcd4="" class="price">₹ 4450.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/10.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Ratnavali Jewels American Diamond Cz Gold
                Plated Necklace Set Tennis Necklace Single Line Solitaire Set With Chain &amp; Earring For Women</div>
              <p data-v-68d7bcd4="" class="price">₹ 4000.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/11.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">chandrika pearls gems &amp; jewellers Sania
                Mirza Style Without Piercing Clip on Pressing Type Nose Ring for Women &amp; Girls</div>
              <p data-v-68d7bcd4="" class="price">₹ 278.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/12.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Chandrika Pearls Gems &amp; Jewellers Sterling
                Silver Pendant Earring with Swarovski Crystal for Girls</div>
              <p data-v-68d7bcd4="" class="price">₹ 1060.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/13.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Chandrika Pearls Gems &amp; Jewellers 92.5
                Sterling Silver Real Diamond Valentine Crown King Queen Ring for Girls &amp; Women</div>
              <p data-v-68d7bcd4="" class="price">₹ 1920.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/14.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Chandrika Pearls Gems &amp; Jewellers 925 Pure
                Sterling Silver Letters Initial Pendant with Gold Polish (R)</div>
              <p data-v-68d7bcd4="" class="price">₹ 476.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/15.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Chandrika Pearls Gems &amp; Jewellers
                Dhanteras Brass Hindu Puja Camphor Burner Lamp Panch Aarti - 5 Face For Puja</div>
              <p data-v-68d7bcd4="" class="price">₹ 1880.00</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://digitalgoldclub.site/uploads/images/16.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Jewels Galaxy Designer American Diamond Gold
                Plated Bangles for Women/Girls</div>
              <p data-v-68d7bcd4="" class="price">₹ 1999.00</p>
            </ol>
          </li>
        </ul>
      </div>
    </div>
    <div data-v-405e9a63="" data-v-68d7bcd4="" class="footer">
      <ul data-v-405e9a63="" class="nav_foot">
        <li data-v-405e9a63="" onclick="window.location.href='#/'" class="active"><img data-v-405e9a63=""
            src="../assets/icons/home.png"
            alt=""><span data-v-405e9a63="">Home</span></li>
        <li data-v-405e9a63="" onclick="window.location.href='#/search'" class=""><img data-v-405e9a63=""
            src="../assets/icons/search.png"
            alt=""><span data-v-405e9a63="">Search</span></li>
        <li data-v-405e9a63="" class="" onclick="window.location.href='#/win'" id="game" style="display: none;"><img
            data-v-405e9a63=""
            src="../assets/icons/trophy.png"
            alt=""><span data-v-405e9a63="">Win</span></li>
        <li data-v-405e9a63="" onclick="window.location.href='#/login'" class=""><img data-v-405e9a63=""
            src="../assets/icons/avatar.png"
            alt=""><span data-v-405e9a63="">My</span></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  data() {
        return {
           count:1
        }

    },
    beforeUnmount: function () {
        console.log("unmount");
        clearInterval(this.repeat);

    },
  mounted: function () {
    this.check();
    this.repeat=setInterval(() => this.slide(), 4000)
  },
  methods: {
    check() {
      if (localStorage.getItem('username') == null) {
        document.getElementById("game").style.display = "none";
      } else {
        document.getElementById("game").style.display = "";
      }
    },
    slide() { 
      document.querySelectorAll(".slides img").forEach(function (photo) {
        photo.classList.remove("outleft");
        photo.classList.remove("outright");
      });
      document.querySelectorAll(".slides img")[this.count - 1].classList.remove("inleft");
      document.querySelectorAll(".slides img")[this.count - 1].classList.remove("inright");
      document.querySelectorAll(".slides img")[this.count - 1].classList.add("outleft");
      this.count=this.count+1;
      // *** Looping document.querySelectorAll(".slides img") *** //
      if (this.count > document.querySelectorAll(".slides img").length) { this.count = 1; } // *** if reached end go to first slide *** //
      if (this.count < 1) { this.count = 4; } // *** if reached first go to last one *** //


      document.querySelectorAll(".slides img")[this.count - 1].classList.add("inleft");
      console.log(this.count);
    }
  }
}
</script>

<style>
.button,
.checkmark {
  background-color: #dedede;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(190, 190, 220, 0.2);
  color: white;
  height: 30px;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.2s;
  width: 30px;
}

.button:hover,
.checkmark:hover {
  background-color: #ccc;
}

.button:active,
.checkmark:active {
  background: #2196F3;
  box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.autoplay-controls {
  cursor: pointer;
}

.autoplay-controls:active>.checkmark {
  background: #2196F3;
  box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.slider_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 900px;
}

.slide_this.counter {
  float: right;
  margin: 0 1rem;
}

.slides {
  height: 500px;
  overflow: hidden;
  position: relative;
  width: 900px;
}

.navigation-buttons {
  display: inherit;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.slider_navigation {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}



.autoplay-sub {
  float: left;
  line-height: 30px;
  font-size: 0.7rem;
}

.checkmark {
  float: left;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 11px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button-left:after,
.button-right:after {
  border: solid white;
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  width: 8px;
}

.button-left:after {
  left: 11px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.button-right:after {
  left: 7px;
  top: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.inright {
  -webkit-animation: goInL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goInL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 300 !important;
}

@-webkit-keyframes goInL {
  0% {
    left: -900px;
  }

  100% {
    left: 0;
  }
}

@keyframes goInL {
  0% {
    left: -900px;
  }

  100% {
    left: 0;
  }
}

.outright {
  -webkit-animation: goOutL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goOutL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 200;
}

@-webkit-keyframes goOutL {
  0% {
    left: 0;
  }

  100% {
    left: 900px;
  }
}

@keyframes goOutL {
  0% {
    left: 0;
  }

  100% {
    left: 900px;
  }
}

.inleft {
  -webkit-animation: goInR 0.8s cubic-bezier(1, 1.4, 0.61, 0.74);
  animation: goInR 0.8s cubic-bezier(1, 1.4, 0.61, 0.74);
  position: relative;
  z-index: 300 !important;
}

@-webkit-keyframes goInR {
  0% {
    left: 900px;
  }
  50% {
    left: 400px;
  }
  100% {
    left: 0;
  }
}

@keyframes goInR {
  0% {
    left: 900px;
  }
  50% {
    left: 400px;
  }

  100% {
    left: 0;
  }
}

.outleft {
  -webkit-animation: goOutR 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goOutR 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 200;
}

@-webkit-keyframes goOutR {
  0% {
    left: 0;
  }

  100% {
    left: -900px;
  }
}

@keyframes goOutR {
  0% {
    left: 0;
  }

  100% {
    left: -900px;
  }
}

.pic {
  left: 0;
  height: 75%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 54%;
}

.pic:first-child {
  z-index: 100;
}
</style>


